@font-face {
  font-family: "GT Walsheim";
  src: url("https://acloud.guru/fonts/gt-walsheim/gt-walsheim-web.ttf") format("truetype")
}

@font-face {
  font-family: "GT Walsheim";
  font-weight: 800;
  src: url("https://acloud.guru/fonts/gt-walsheim/gt-walsheim-bold-web.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim";
  font-weight: 600;
  src: url("https://acloud.guru/fonts/gt-walsheim/gt-walsheim-medium-web.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim";
  font-weight: 300;
  src: url("https://acloud.guru/fonts/gt-walsheim/gt-walsheim-light-web.ttf") format("truetype");
}